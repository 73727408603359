import React, {ChangeEvent, useRef, useState} from 'react';
import TitleBar from "../../components/TitleBar";
import {t} from "i18next";
import {Button} from "react-bootstrap";
import {read} from "node:fs";
import LoadingComponent from "../../components/LoadingComponent";
import ErrorModal from "../../components/ErrorModal";
import {ExportProducts, ImportProducts, ResetProductsCache} from "../../api/services/productServices";
import {useNavigate} from "react-router-dom";

const ProductImportExport: React.FC = () => {
    const fileInput = useRef<HTMLInputElement>(null);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<React.ReactNode>();

    const navigate = useNavigate();

    async function handleImportProducts(event: ChangeEvent<HTMLInputElement>) {

        const files = event.target.files;
        if (!files || files.length === 0) return;

        const file = files[0];
        try {
            setIsLoading(true);
            const base64 = await readFileAsBase64(file);
            console.log("file read");
            console.log("importing...");

            const response = await ImportProducts(base64);
            console.log("file imported");

            ResetProductsCache();

            alert(response.message);
            navigate("../products");


        } catch (error) {
            setError(<p dangerouslySetInnerHTML={{__html: (error as Error).message}}></p>);
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    function readFileAsBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const result = reader.result as string;
                const base64 = result.split("base64,")[1];
                resolve(base64);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }

    async function handleExportProducts() {
        try {
            setIsLoading(true);
            console.log("exporting...");

            const fileContent = await ExportProducts();
            console.log("file exported", fileContent);

            const link = document.createElement('a');
            link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${fileContent}`;
            link.download = "Productos.xlsx";
            link.click();
            
            navigate("../products");


        } catch (error) {
            setError(<p dangerouslySetInnerHTML={{__html: (error as Error).message}}></p>);
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    if (isLoading) return <LoadingComponent/>;

    if (error)
        return (
            <ErrorModal errorMessage={error} onOkClick={() => setError(null)}/>
        );

    return (
        <div>
            <TitleBar title={t("product-import-export-title")}/>

            <div className="my-2">
                <p>Exporta el catálogo de productos para obtener un archivo de ejemplo pre-cargado con los productos
                    actuales del catálogo.</p>
                <Button className="mt-1 w-100" onClick={handleExportProducts}>
                    Exportar productos
                </Button>
            </div>

            <hr/>

            <div className="my-2">
                <p>Importa un archivo para agregar o actualizar productos del catálogo.</p>
                <Button className="mt-1 w-100"
                        onClick={() => {
                            if (fileInput.current)
                                fileInput.current.click();
                        }}>
                    Importar productos
                </Button>
                <input
                    type="file"
                    title="Archivo a importar"
                    ref={fileInput}
                    accept=".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    style={{display: "none"}}
                    onChange={handleImportProducts}
                />
            </div>
        </div>
    );
};

export default ProductImportExport;
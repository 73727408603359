import { useEffect, useRef, useState } from "react";
import { Order, OrderItem } from "../../../api/models/orderModel";
import TitleBar from "../../../components/TitleBar";
import { useNavigate, useParams } from "react-router-dom";
import {
    AssignPickingOrder,
    OrderFulfillingCompleted,
} from "../../../api/services/ordersServices";
import LabelGroup from "../../../components/LabelGroup";
import DeliveryTypeBadge from "../Components/DeliveryTypeBadge";
import FulfillOrderItemsList from "./FulfillOrderItemsList";
import FulfillOrderItem from "./FulfillOrderItem";
import React from "react";
import ErrorModal from "../../../components/ErrorModal";
import LoadingComponent from "../../../components/LoadingComponent";
import { useSession } from "../../../api/context/SessionContext";
import { GetErrorMessage } from "../../../api/helpers/apiHelpers";
import { t } from "i18next";

//TODO: Al regresar a la pagina de surtir pedidos, cargar el rango de fechas previo

const FulfillOrderPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const session = useSession();

    const [order, setOrder] = useState<Order>();

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<React.ReactNode>();

    const [showItemsList, setShowItemsList] = useState<boolean>(true);
    const [currentItem, setCurrentItem] = useState<OrderItem>();
    const initializing = useRef(false); // Esto es para evitar que se ejecute el useEffect dos veces

    useEffect(() => {
        if (!initializing.current) fetchData();
    }, [initializing]);

    const fetchData = async () => {
        initializing.current = true;

        const orderId = params.orderId ? parseInt(params.orderId) : -1;
        if (orderId === -1) {
            setErrorMessage(t("fulfillOrderPage_error_parameter"));
            return;
        }
        try {
            setIsLoading(true);
            setErrorMessage(undefined);
            const response = await AssignPickingOrder(
                orderId,
                session.getLoggedUser()!.userId
            );
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }

            setOrder({ ...response.order });
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
        } finally {
            setIsLoading(false);
        }
    };

    const finishOrder = async (): Promise<boolean> => {
        try {
            setIsLoading(true);
            setErrorMessage(undefined);

            const response = await OrderFulfillingCompleted(order!.id);
            if (!response.success) {
                setErrorMessage(response.error);
                return false;
            }

            return true;
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    if (errorMessage)
        return (
            <ErrorModal
                errorMessage={errorMessage}
                onRetryClick={!order ? fetchData : undefined}
                onOkClick={() => {
                    if (!order) navigate(-1);
                    else setErrorMessage(undefined);
                }}
            />
        );

    if (isLoading || !order) return <LoadingComponent />;

    return (
        <div id="NewFulfillOrderPage">
            <TitleBar
                title={`${t("fulfillOrderPage_title")} ${order.orderNumber}`}
                backUrl="../fulfill-orders"
            />

            <div id="orderInfo" className="mt-3">
                <div className="d-flex justify-content-between">
                    <h5>{order.customer.name}</h5>
                    <LabelGroup caption={t("promised_delivery_date")}>
                        {order.promisedDeliveryDate}
                    </LabelGroup>
                </div>
                <div className="mt-2">
                    <DeliveryTypeBadge deliveryType={order.deliveryType} />
                </div>
            </div>

            <FulfillOrderItemsList
                show={showItemsList}
                order={order}
                onItemClick={(item) => {
                    setShowItemsList(false);
                    setCurrentItem(item);
                }}
                onFinish={async (order) => {
                    if (await finishOrder()) navigate("../fulfill-orders");
                }}
                onFinishAndPack={async (order) => {
                    if (await finishOrder())
                        navigate(
                            `../pack-order/${order.id}?returnUrl=../fulfill-orders`
                        );
                }}
            />

            <FulfillOrderItem
                order={order}
                item={currentItem}
                onItemChanged={(item: OrderItem) => {
                    currentItem!.quantityFulfilled = item.quantityFulfilled;
                    setOrder({ ...order });
                }}
                onNavigateToItem={(item: OrderItem) => setCurrentItem(item)}
                onPause={() => {
                    setCurrentItem(undefined);
                    setShowItemsList(true);
                }}
                onFinish={() => {
                    setCurrentItem(undefined);
                    setShowItemsList(true);
                }}
            />
        </div>
    );
};

export default FulfillOrderPage;

import {faExchange, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useState} from "react";
import {Button, FormSelect} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";
import {Product} from "../../api/models/productModel";
import {BottomLevelCategory, GetBottomLevelCategories,} from "../../api/services/productCategoriesServices";
import {GetProducts} from "../../api/services/productServices";
import SearchEntry from "../../components/SearchEntry";
import LoadingComponent from "../../components/LoadingComponent";
import ErrorModal from "../../components/ErrorModal";
import {useSession} from "../../api/context/SessionContext";
import {t} from "i18next";

const ProductsListPage = () => {
    const navigate = useNavigate();
    const session = useSession();

    const [products, setProducts] = useState<Product[]>([]);
    const [categories, setCategories] = useState<BottomLevelCategory[]>([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState<number>(0);

    const [searchText, setSearchText] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState(true);
    const [loadingError, setLoadingError] = useState<React.ReactNode>();

    const [numberOfProducts, setNumberOfProducts] = useState<number | null>(null);

    const [productsList, setProductsList] = useState<Product[]>([]);
    
    const location = useLocation();
    
    useEffect(() => {
        async function init() {
            // Valida que tenga acceso a esta opción
            if (
                !session.isLoggedIn() ||
                !session.hasDoor("7fc266e4-745b-4da2-b8ba-3c90bdc15e0b")
            ) {
                alert(t("dont_have_permission"));
                navigate("../");
            }

            const allProducts  =             await fetchData();
            setProducts((allProducts as Product[]));
            console.log("fetch data...", (allProducts as Product[]).length);

            const list = filterProducts((allProducts as Product[]));
            setProductsList(list);
            console.log("filtered data...", list.length);

        }

        init();
    }, []);

    useEffect(() => {
     setProductsList(filterProducts(products));
    }, [selectedCategoryId, searchText]);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            setLoadingError(undefined);

            const categories = await GetBottomLevelCategories();
            setCategories(categories);

            return await GetProducts();
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setLoadingError(
                <p>
                    {t("error_loading")} <br/> {t("please_try_again")}.
                </p>
            );

            console.log(message, error);
        } finally {
            setIsLoading(false);
        }
    };

    const filterProducts = (allProducts : Product[]) => {
        let ds = allProducts;

        const selectedCategory = categories.find(
            (x) => x.id === selectedCategoryId
        );

        if (selectedCategory)
            ds = ds.filter(
                (x) =>
                    x.productCategoryId === selectedCategory.categoryId &&
                    (!x.productSubCategoryId ||
                        x.productSubCategoryId ===
                        selectedCategory.subCategoryId)
            );

        if (searchText) {
            let searchTextLower = searchText.toLocaleLowerCase();
            ds = ds.filter((x) =>
                x.name.toLocaleLowerCase().includes(searchTextLower)
            );
        }

        const list =
            ds.sort((a, b) =>
                a.order < b.order ? -1 : a.order > b.order ? 1 : 0
            );

        if (list.length == 0)
            setNumberOfProducts(null);
        else
            setNumberOfProducts(list.length);

        return list;

    };

    if (isLoading) return <LoadingComponent/>;

    if (loadingError)
        return (
            <ErrorModal
                errorMessage={loadingError}
                onRetryClick={fetchData}
                onOkClick={() => setLoadingError(undefined)}
            />
        );

    return (
        <div>
            <div className="d-flex justify-content-between my-1">
                <h1>{t("productsListPage_title")} {numberOfProducts && `(${numberOfProducts})`}</h1>
                <Button variant="outline-secondary"
                        onClick={() => navigate("../products-import-export")}>
                    <FontAwesomeIcon icon={faExchange}/>
                </Button>
            </div>


            <SearchEntry onChange={(e) => setSearchText(e)}/>

            <div id="toolbar" className="toolbar">
                <FormSelect
                    disabled={categories.length === 0}
                    onChange={(e) =>
                        setSelectedCategoryId(parseInt(e.currentTarget.value))
                    }
                >
                    <option value={0}>
                        {t("productsListPage_all_categories")}
                    </option>
                    {categories?.map((x, i) => (
                        <option key={x.id} value={x.id}>
                            {x.name}
                        </option>
                    ))}
                </FormSelect>
                <Button
                    id="addCategory"
                    variant="success"
                    onClick={() => navigate("../products-add")}
                >
                    <FontAwesomeIcon icon={faPlus}/>
                </Button>
            </div>

            <div id="productsList">
                {productsList.map((x, i) => (
                    <div
                        className="panel"
                        key={i}
                        onClick={() => navigate(`../products/${x.id}`)}
                    >
                        <div className="row">
                            <div className="col-2">
                                <img
                                    style={{
                                        maxWidth: "48px",
                                        maxHeight: "48px",
                                    }}
                                    src={x.thumbUrl}
                                    alt={x.name}
                                />
                            </div>
                            <div className="col d-flex align-items-center">
                                {x.name}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductsListPage;

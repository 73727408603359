import { GetISODate, RangoFechas } from "../helpers/datesHelpers";
import {
    eDeliveryType,
    eOrderStatus,
    Order,
    OrderItem,
    OrderPayment,
    OrdersByProduct as OrderByProduct,
    ProductOrdered,
    Address,
    eShippingType,
} from "../models/orderModel";
import { GetPaymentMethods } from "./paymentMethodServices";
import { BuildUrl, CreateAxiosInstance } from "../helpers/apiHelpers";
import { Product } from "../models/productModel";

const axiosInstance = CreateAxiosInstance();

export type GetOrdersViewModel = {
    id: string;
    orderNumber: number;
    promisedDeliveryDate: Date;
    customerName: string;
    deliveryType: eDeliveryType;
    total: number;
    balance: number;
    itemsCount: number;
};

export type GetOrdersToFulfillResponse = {
    success: boolean;
    message: string;
    orders: OrdersToFulfillViewModel[];
};

export type OrdersToFulfillViewModel = {
    id: string;
    orderNumber: number;
    promisedDeliveryDate: Date;
    customerName: string;
    deliveryType: eDeliveryType;
    total: number;
    balance: number;
    itemsCount: number;
    status: eOrderStatus;
};

export type GetOrdersResponse = {
    success: boolean;
    message: string;
    orders: GetOrdersViewModel[];
};

export const GetOrders = async (
    status: eOrderStatus,
    rangoFechas: RangoFechas
): Promise<GetOrdersResponse> => {
    const desde = GetISODate(rangoFechas.Desde);
    const hasta = GetISODate(rangoFechas.Hasta);

    try {
        const url = BuildUrl(
            `orders?desde=${desde}&hasta=${hasta}&status=${status}`
        );
        const response = await axiosInstance.get(url);
        
        return response.data as GetOrdersResponse;
    } catch (error) {
        console.log("Error getting orders", error);
        throw error;
    }
};

export const GetOrder = async (orderId: string) => {
    try {
        const url = BuildUrl(`orders/${orderId}`);
        const response = await axiosInstance.get(url);
        return response.data;
    } catch (error) {
        console.log("Error getting orders", error);
        throw error;
    }
};

export const ResendOrderEmail = async (orderId: string) => {
    try {
        const url = BuildUrl(`orders/${orderId}/sendmail`);
        const response = await axiosInstance.get(url);
        return response.data;
    } catch (error) {
        console.log("Error resending order email", error);
        throw error;
    }
};

export type GetProductsOrderedResponse = {
    success: boolean;
    message: string;
    productsOrdered: ProductOrdered[];
};

export const GetProductsOrdered = async (
    status: eOrderStatus,
    rangoFechas: RangoFechas
): Promise<GetProductsOrderedResponse> => {
    const desde = GetISODate(rangoFechas.Desde);
    const hasta = GetISODate(rangoFechas.Hasta);

    try {
        const url = BuildUrl(
            `orders/products?desde=${desde}&hasta=${hasta}&status=${status}`
        );
        const response = await axiosInstance.get(url);
        return response.data as GetProductsOrderedResponse;
    } catch (error) {
        console.log("Error getting orders products", error);
        throw error;
    }
};

export type GetOrdersByProductResponse = {
    success: boolean;
    message: string;
    product: Product;
    orders: OrderByProduct[];
};

export const GetOrdersByProduct = async (
    status: eOrderStatus,
    rangoFechas: RangoFechas,
    productId: number
): Promise<GetOrdersByProductResponse> => {
    const desde = GetISODate(rangoFechas.Desde);
    const hasta = GetISODate(rangoFechas.Hasta);

    try {
        const url = BuildUrl(
            `orders/products/${productId}?desde=${desde}&hasta=${hasta}&status=${status}`
        );
        const response = await axiosInstance.get(url);
        return response.data as GetOrdersByProductResponse;
    } catch (error) {
        console.log("Error getting orders products", error);
        throw error;
    }
};

export type CancelOrderResponse = {
    success: boolean;
    message: string;
};

export const CancelOrder = async (
    orderId: number,
    userId: string
): Promise<CancelOrderResponse> => {
    try {
        const url = BuildUrl(`orders/${orderId}?userId=${userId}`);
        const response = await axiosInstance.delete(url);
        return response.data as CancelOrderResponse;
    } catch (error) {
        console.log("Error canceling order", error);
        throw error;
    }
};

export const UpdateOrder = async (order: Order) => {
    try {
        const url = BuildUrl(`orders/${order.id}`);

        const request = {
            name: order.customer.name,
            phone: order.customer.phone,
            email: order.customer.email,
            deliveryType: order.deliveryType,
            deliveryFee: order.deliveryFee,
            address: order.shippingAddress,
            promisedDate: order.promisedDeliveryDate,
        };
        const response = await axiosInstance.post(url, request);

        return response.data;
    } catch (error) {
        console.log("Error updating order", error);
        throw error;
    }
};

export const UpdateOrderItem = async (order: Order, item: OrderItem) => {
    try {
        const url = BuildUrl(`orders/${order.id}/items/${item.id}`);

        const request = {
            productId: item.product.id,
            quantity: item.quantity,
            notes: item.notes,
        };

        const response = await axiosInstance.put(url, request);

        return response.data;
    } catch (error) {
        console.log("Error updating order item", error);
        throw error;
    }
};

export const DeleteOrderItem = async (order: Order, item: OrderItem) => {
    try {
        const url = BuildUrl(`orders/${order.id}/items/${item.id}`);

        const response = await axiosInstance.delete(url);

        return response.data;
    } catch (error) {
        console.log("Error deleting order item", error);
        throw error;
    }
};

export const AddOrderItem = async (order: Order, item: OrderItem) => {
    try {
        const url = BuildUrl(`orders/${order.id}/items`);

        const request = {
            productId: item.product.id,
            quantity: item.quantity,
            notes: item.notes,
        };

        const response = await axiosInstance.post(url, request);

        return response.data;
    } catch (error) {
        console.log("Error adding order item", error);
        throw error;
    }
};

export const CalculateOrder = (order: Order) => {
    order.items.forEach((i) => (i.amount = i.quantity * i.product.price));
    order.subTotal = order.items.reduce<number>((accumulator, current) => {
        return accumulator + current.amount;
    }, 0);

    if (order.deliveryType !== eDeliveryType.Delivery) order.deliveryFee = 0;

    order.total = order.subTotal - order.discount + order.deliveryFee;
};

export const CalculateOrderItem = (item: OrderItem) => {
    item.amount = item.quantity * item.price;
};

export const GetOrdersToFulfill = async (
    rangoFechas: RangoFechas | null,
    userId: string
) => {
    if (!rangoFechas) {
        // Cuando no tiene filtro por fecha va, desde 2022 hasta una año hacia adelante
        const fechaActual = new Date();
        const fechaFinal = new Date(
            fechaActual.getFullYear() + 1,
            fechaActual.getMonth(),
            fechaActual.getDay()
        );
        rangoFechas = { Desde: new Date(2022, 11, 13), Hasta: fechaFinal };
    }

    const desde = GetISODate(rangoFechas.Desde);
    const hasta = GetISODate(rangoFechas.Hasta);

    try {
        const url = BuildUrl(
            `orders/picking?desde=${desde}&hasta=${hasta}&userId=${userId}`
        );
        const response = await axiosInstance.get(url);
        return response.data as GetOrdersToFulfillResponse;
    } catch (error) {
        console.log("Error getting orders", error);
        throw error;
    }
};

export const AssignPickingOrder = async (orderId: number, userId: string) => {
    try {
        const url = BuildUrl(`orders/${orderId}/picking`);

        const request = {
            userId,
        };
        const response = await axiosInstance.post(url, request);

        return response.data;
    } catch (error) {
        console.log("Error assigning order", error);
        throw error;
    }
};

export const FulfillItem = async (
    orderId: number,
    orderItemId: number,
    quantityFulfilled: number
) => {
    try {
        const url = BuildUrl(`orders/${orderId}/picking`);
        const request = {
            orderItemId,
            quantityFulfilled,
        };
        const response = await axiosInstance.put(url, request);
        return response.data;
    } catch (error) {
        console.log("Error fulfilling item", error);
        throw error;
    }
};

export const GetFulfillingOrder = (): Order | null => {
    //TODO: Call API send signed user as parameter
    return null;
};

export const OrderFulfillingCompleted = async (orderId: number) => {
    try {
        const url = BuildUrl(`orders/${orderId}/picking/complete`);

        const response = await axiosInstance.post(url, {});
        return response.data;
    } catch (error) {
        console.log("Error completing order", error);
        throw error;
    }
};

export const OrderFulfillingPaused = (): boolean => {
    //TODO: Call API
    //TODO: Capture Picked position
    //TODO: Remove assigned user
    return true;
};

export type GetOrdersToPackViewModel = {
    id: string;
    orderNumber: number;
    status: eOrderStatus;
    deliveryType: eDeliveryType;
    promisedDeliveryDate: string;
    customerName: string;
    itemsCount: number;
};

export type GetOrdersToPackResponse = {
    success: boolean;
    message: string;
    orders: GetOrdersToPackViewModel[];
};

export const GetOrdersToPack = async (): Promise<GetOrdersToPackResponse> => {
    try {
        const url = BuildUrl(`orders/packing`);
        const response = await axiosInstance.get(url);

        return response.data as GetOrdersToPackResponse;
    } catch (error) {
        console.log("Error getting orders to pack", error);
        throw error;
    }
};

export type SetOrderPackedResponse = {
    success: boolean;
    message: string;
};

export const SetOrderPacked = async (
    orderId: number,
    packages: number,
    userId: string
): Promise<SetOrderPackedResponse> => {
    try {
        const request = { Packages: packages, UserId: userId };

        const url = BuildUrl(`orders/${orderId}/packing`);
        const response = await axiosInstance.post(url, request);

        return response.data as SetOrderPackedResponse;
    } catch (error) {
        console.log("Error setting orders packed", error);
        throw error;
    }
};

export type GetOrdersToShipViewModel = {
    id: number;
    orderNumber: string;
    packages: number;
    shippingAddress: Address;
    customerName: string;
    balance: number;
    promisedDeliveryDate: string;
};

export type GetOrdersToShipResponse = {
    success: boolean;
    message: string;
    orders: GetOrdersToShipViewModel[];
};

export const GetOrdersToShip = async (): Promise<GetOrdersToShipResponse> => {
    try {
        const url = BuildUrl(`orders/shipping`);
        const response = await axiosInstance.get(url);

        return response.data as GetOrdersToShipResponse;
    } catch (error) {
        console.log("Error getting orders to ship", error);
        throw error;
    }
};

export type SetOrderShippedResponse = {
    success: boolean;
    message: string;
};

export const SetOrderShipped = async (
    orderId: number,
    userId: string,
    shippingType: eShippingType,
    shippingService: string,
    shippingTrackingGuide?: string
) => {
    //TODO: Hacer cargo a tarjeta
    try {
        const request = {
            UserId: userId,
            ShippingType: shippingType,
            ShippingService: shippingService,
            ShippingTrackingGuide: shippingTrackingGuide,
        };
        const url = BuildUrl(`orders/${orderId}/shipping`);
        const response = await axiosInstance.post(url, request);
        return response.data as SetOrderShippedResponse;
    } catch (error) {
        console.log("Error setting orders shipped", error);
        throw error;
    }
};

export type GetOrdersToDeliverViewModel = {
    id: number;
    orderNumber: string;
    packages: number;
    customerName: string;
    promisedDeliveryDate: string;
    deliveryType: eDeliveryType;
    shippingService: string;
    shippingTrackingGuide: string;
    balance: number;
    total: number;
    totalFullfilled: number;
};

export type GetOrdersToDeliverResponse = {
    success: boolean;
    message: string;
    orders: GetOrdersToDeliverViewModel[];
};

export const GetOrdersToDeliver =
    async (): Promise<GetOrdersToDeliverResponse> => {
        try {
            const url = BuildUrl(`orders/delivery`);
            const response = await axiosInstance.get(url);

            return response.data as GetOrdersToDeliverResponse;
        } catch (error) {
            console.log("Error getting orders to deliver", error);
            throw error;
        }
    };

export type SetOrderDeliveredResponse = {
    success: boolean;
    message: string;
};

export const SetOrderDelivered = async (
    orderId: number,
    payments: Array<OrderPayment>
) => {
    try {
        // copy payments to request, the payment method just needs the id
        const requestPayments = payments.map((p) => {
            return {
                amount: p.amount,
                paymentMethod: p.paymentMethod.id,
            };
        });

        const request = {
            payments: requestPayments,
        };

        console.log("Request", request);

        const url = BuildUrl(`orders/${orderId}/delivery`);
        const response = await axiosInstance.post(url, request);
        return response.data as SetOrderDeliveredResponse;
    } catch (error) {
        console.log("Error setting orders delivered", error);
        throw error;
    }
};

export type GetOrdersReceivableResponse = {
    success: boolean;
    message: string;
    orders: GetOrdersReceivableViewModel[];
};

export type GetOrdersReceivableViewModel = {
    id: number;
    orderNumber: string;
    customerName: string;
    deliveredDate: string;
    totalFulfilled: number;
    balance: number;
};

export const GetOrdersReceivable =
    async (): Promise<GetOrdersReceivableResponse> => {
        try {
            const url = BuildUrl(`orders/receivable`);
            const response = await axiosInstance.get(url);

            return response.data as GetOrdersReceivableResponse;
        } catch (error) {
            console.log("Error getting orders to deliver", error);
            throw error;
        }
    };

export type GetOrderReceivableResponse = {
    success: boolean;
    message: string;
    order: GetOrderReceivableViewModel;
};

export type GetOrderReceivableViewModel = {
    id: number;
    orderNumber: string;
    customerName: string;
    deliveredDate: string;
    totalFulfilled: number;
    balance: number;
    payments: Array<OrderPayment>;
};

export const GetOrderReceivable = async (
    orderId: number
): Promise<GetOrderReceivableResponse> => {
    try {
        const url = BuildUrl(`orders/${orderId}/receivable`);

        const response = await axiosInstance.get(url);

        return response.data as GetOrderReceivableResponse;
    } catch (error) {
        console.log("Error getting orders to deliver", error);
        throw error;
    }
};

export const CalculateOrderFulfilledTotal = (order: Order) => {
    const subTotal = order.items.reduce<number>((accumulator, current) => {
        return accumulator + current.price * current.quantityFulfilled;
    }, 0);

    return subTotal - order.discount + order.deliveryFee;
};

export const CalculateOrderBalance = (order: Order) => {
    const total = CalculateOrderFulfilledTotal(order);

    if (!order.payments) return total;

    const payments = order.payments.reduce<number>((accumulator, current) => {
        return accumulator + current.amount;
    }, 0);

    if (payments > total) return 0;
    return total - payments;
};

export const AddPayment = (order: Order, payment: OrderPayment) => {
    //TODO: Call API
    if (!order.payments) order.payments = [];
    order.payments.push(payment);
};

export const UpdatePayment = (order: Order, payment: OrderPayment) => {
    //TODO: Call API
    if (!order.payments) order.payments = [];
    const i = order.payments!.findIndex((x) => x.id === payment?.id);
    if (i < 0) throw Error(`No se encuentra el pago ${payment.id}`);

    order.payments![i] = payment;
};

export const DeletePayment = (order: Order, payment: OrderPayment) => {
    //TODO: Call API
    if (!order.payments) order.payments = [];
    order.payments = order.payments.filter((x) => x.id !== payment.id);
};

export const IsOrderEditable = (orderStatus: eOrderStatus) =>
    orderStatus === eOrderStatus.Pending ||
    orderStatus === eOrderStatus.Picking;

export const PrimeOrderPayments = async () => {
    const methods = await GetPaymentMethods();
    const primed = Array<OrderPayment>();
    methods.paymentMethods.map((m, i) =>
        primed.push({
            id: i,
            date: new Date(),
            paymentMethod: m,
            amount: 0,
        })
    );
    return primed;
};

export const UpdatePayments = async (
    orderId: number,
    payments: Array<OrderPayment>
) => {
    try {
        const url = BuildUrl(`orders/${orderId}/receivable`);

        const requestPayments = payments.map((p) => {
            return {
                amount: p.amount,
                paymentMethodId: p.paymentMethod.id,
            };
        });

        const request = { payments: requestPayments };

        const response = await axiosInstance.put(url, request);

        return response.data as GetOrderReceivableResponse;
    } catch (error) {
        console.log("Error getting orders to deliver", error);
        throw error;
    }
};

import { PaymentMethod } from "./paymentMethodModel";
import { Product } from "./productModel";

export enum eOrderStatus {
    Pending, // When the customer places the order, but it has not yet been processed
    Picking, // Gathering the items
    Picked, // All items gathered, waiting to be packaged
    Packaged, //The order is packaged and waiting to be shipped or delivered
    Shipped, //The order has been shipped and is on its way to the customer
    Delivered, //The order has been sucessfully delivered to the customer
    Cancelled = 10, //The order has been cancelled, either by the customer or by the operator
}

export enum eShippingType {
    Own,
    ThirdParty,
}

export const eOrderStatusName = [
    "por Surtir",
    "Surtiendo",
    "Empacando",
    "Empacado",
    "Enviado",
    "Entregado",
    "Cancelado",
];

export type Address = {
    street: string;
    outsideNumber: string;
    innerNumber: string;
    neighborhood: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    lat: number | null;
    lng: number | null;
};

export type Customer = {
    userId: string;
    name: string;
    email: string;
    phone: string;
};

export enum eDeliveryType {
    Pickup,
    Delivery,
}

export enum ePaymentType {
    Card,
    Cash,
}

export const GetPaymentTypeNames = () => ["Tarjeta", "Efectivo"];

export type OrderItem = {
    id: number;
    product: Product;
    quantity: number;
    price: number;
    amount: number;
    notes?: string;
    quantityFulfilled: number;
};

export type OrderPayment = {
    id: number;
    date: Date;
    paymentMethod: PaymentMethod;
    paymentReference?: string;
    amount: number;
};

export type Order = {
    id: number;
    orderNumber: string;
    status: eOrderStatus; // Current order status
    dateEntered: Date; // Date when the customer places the order
    promisedDeliveryDate: string; // Date when the order should be delivered
    customer: Customer;
    shippingAddress?: Address;
    items: Array<OrderItem>;

    subTotal: number;
    discount: number;
    deliveryFee: number;
    total: number;

    deliveryType: eDeliveryType;
    paymentType: ePaymentType;

    notes?: string;

    packages?: number;

    shippingType?: eShippingType;
    shippingService?: string;
    shippingTrackingGuide?: string;

    payments?: Array<OrderPayment>;
};

export type ProductOrdered = {
    product: Product;
    quantity: number;
};

export type OrdersByProduct = {
    orderId: number;
    orderNumber: string;
    promisedDeliveryDate: string;
    customerName: string;
    quantity: number;
    unitOfMeasure: string;
    quantityFulfilled: number;
};

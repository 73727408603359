import React from 'react';
import {Store} from "../../api/models/storeModel";
import {FormControl, FormLabel} from "react-bootstrap";
import {t} from "i18next";
const StoreSettingsExtras = ({ store, onPropertyChanged }: { store: Store , onPropertyChanged: (propertyName: string, value: any) => void}) => {
    return (
        <div className="panel">
            <h6>Extras</h6>
            <FormLabel>
                {t("store-settings-extras-bccOrdersEmail-label")}:
            </FormLabel>
            <FormControl
                type="text"
                placeholder={t("store-settings-extras-bccOrdersEmail-placeholder")}
                value={store.bccOrdersEmail}
                onChange={(e) =>
                    onPropertyChanged("bccOrdersEmail", e.currentTarget.value)
                }
            />
        </div>
    )
}
export default StoreSettingsExtras;
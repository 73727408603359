import { useEffect, useState } from "react";
import DateRangeLink from "../../../components/DateRangeLink";
import LabelGroup from "../../../components/LabelGroup";
import TitleBar from "../../../components/TitleBar";
import {
    FechaEntregaDefault,
    GetFechaDesde,
    GetFechaHasta,
    RangoFechas,
} from "../../../api/helpers/datesHelpers";
import {
    GetOrdersToFulfill,
    OrdersToFulfillViewModel,
} from "../../../api/services/ordersServices";
import SearchEntry from "../../../components/SearchEntry";
import Tabs from "../../../components/Tab";
import { FormatDate, FormatDecimal } from "../../../api/helpers/formatters";
import DeliveryTypeBadge from "../Components/DeliveryTypeBadge";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import LoadingComponent from "../../../components/LoadingComponent";
import ErrorModal from "../../../components/ErrorModal";
import React from "react";
import { GetErrorMessage } from "../../../api/helpers/apiHelpers";
import PullToRefresh from "react-simple-pull-to-refresh";
import { useSession } from "../../../api/context/SessionContext";
import { eOrderStatus } from "../../../api/models/orderModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarXmark, faTruck } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";

const FulfillOrdersPage = () => {
    const navigate = useNavigate();
    const session = useSession();

    const [searchText, setSearchText] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<React.ReactNode>();

    const [filterDeliveryType, setFilterDeliveryType] = useState<number>(0);

    const [orders, setOrders] = useState<OrdersToFulfillViewModel[]>([]);

    const [filtrarPorFecha, setFiltrarPorFecha] = useState<boolean>(false);
    const [rangoFechas, setRangoFechas] = useState<RangoFechas>({
        Desde: GetFechaDesde(FechaEntregaDefault()),
        Hasta: GetFechaHasta(FechaEntregaDefault()),
    });

    useEffect(() => {
        // Valida que tenga acceso a esta opción
        if (
            !session.isLoggedIn() ||
            !session.hasDoor("702a3220-ec47-4c33-ab07-df600940fd72")
        ) {
            alert(t("dont_have_permission"));
            navigate("../");
            return;
        }

        fetchData();
    }, [rangoFechas, filtrarPorFecha]);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            setErrorMessage(undefined);

            const response = await GetOrdersToFulfill(
                filtrarPorFecha ? rangoFechas : null,
                session.getLoggedUser()!.userId
            );
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }

            setOrders(response.orders);
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
        } finally {
            setIsLoading(false);
        }
    };

    const filterOrders = () => {
        let ds = orders;
        if (!ds) return [];

        if (searchText) {
            let searchTextLower = searchText.toLocaleLowerCase();
            ds = ds.filter(
                (x) =>
                    x.id.toString().includes(searchTextLower) ||
                    x.customerName.toLocaleLowerCase().includes(searchTextLower)
            );
        }

        if (filterDeliveryType > 0)
            ds = ds.filter((x) => x.deliveryType === filterDeliveryType - 1);

        return ds;
    };

    if (isLoading) return <LoadingComponent />;

    if (errorMessage)
        return (
            <ErrorModal
                errorMessage={errorMessage}
                onRetryClick={fetchData}
                onOkClick={() => setErrorMessage(undefined)}
            />
        );

    return (
        <PullToRefresh onRefresh={async () => await fetchData()}>
            <div>
                <TitleBar title="Surtir pedidos">
                    <div className="d-flex mb-2">
                        <LabelGroup caption={`${t("promised_delivery_date")}:`}>
                            {(!filtrarPorFecha && (
                                <Button
                                    variant="link"
                                    className="link text-center"
                                    onClick={() => setFiltrarPorFecha(true)}
                                >
                                    {t("fulfillOrderPage_filter_all_pending")}
                                </Button>
                            )) || (
                                <div className="d-flex align-items-center">
                                    <Button
                                        variant="link"
                                        className="link text-center"
                                        onClick={() =>
                                            setFiltrarPorFecha(false)
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={faCalendarXmark}
                                        />
                                    </Button>
                                    <DateRangeLink
                                        rangoFechas={rangoFechas}
                                        onChange={function (
                                            nuevoRango: RangoFechas
                                        ): void {
                                            setRangoFechas(nuevoRango);
                                        }}
                                    />
                                </div>
                            )}
                        </LabelGroup>
                    </div>
                </TitleBar>

                <SearchEntry onChange={(e) => setSearchText(e)} />

                <Tabs
                    tabs={[
                        t("deliverOrders_filter_all"),
                        t("deliverOrders_filter_pickup"),
                        t("deliverOrders_filter_delivery"),
                    ]}
                    selectedTabIndex={filterDeliveryType}
                    onSelectedTabChanged={(e) => setFilterDeliveryType(e)}
                />

                <div id="list">
                    {filterOrders().map((x, i) => (
                        <div key={i} className="panel">
                            <div className="d-flex justify-content-between">
                                <LabelGroup caption={t("order")}>
                                    {x.orderNumber}
                                </LabelGroup>
                                <LabelGroup
                                    caption={t("promised_delivery_date")}
                                >
                                    {FormatDate(x.promisedDeliveryDate, true)}
                                </LabelGroup>
                            </div>
                            <div>{x.customerName}</div>
                            <div className="mt-1 d-flex justify-content-between align-items-center">
                                <DeliveryTypeBadge
                                    deliveryType={x.deliveryType}
                                />
                                <span>{x.itemsCount} productos</span>
                                <LabelGroup caption="Total">
                                    {FormatDecimal(x.total, true)}
                                </LabelGroup>
                            </div>
                            {x.status === eOrderStatus.Picking && (
                                <div>
                                    <span
                                        className="badge rounded-pill bg-secondary"
                                        style={{ height: "1.4rem" }}
                                    >
                                        {t("Surtiendo")}
                                    </span>
                                </div>
                            )}
                            <Button
                                variant="success"
                                className="mt-2"
                                onClick={() => navigate(`${x.id}`)}
                            >
                                {t("surtir")}
                            </Button>
                        </div>
                    ))}
                </div>
            </div>
        </PullToRefresh>
    );
};

export default FulfillOrdersPage;
